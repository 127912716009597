<template>
  <v-container fluid class="pa-0 ma-0 background"
               style="background-size: cover!important; background-attachment: fixed"
               :style="'background-image: url('+require('@/assets/images/titlePicture.jpg')+')'">
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-row style="max-width: 100vw;" class="pt-10 pl-10">
      <v-col cols="12" class="d-flex justify-center align-center">
        <v-card class="pa-9 transparent-card rounded-xl" max-width="900px">
          <p class="text-uppercase">Starte jetzt kostenlos</p>
          <h1 class="logo mb-3">Weddingflow</h1>
          <p>Bereits ein Account? <a href="/#/login">Einloggen</a></p>

          <v-text-field label="Unternehmensname"
                        outlined
                        dense
                        v-model="user.companyName"
          />
          <v-row>
            <v-col cols="6">
              <v-text-field
                  label="Vorname*"
                  outlined
                  dense
                  v-model="user.firstname"
                  :rules="[rules.required]"/>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  label="Nachname*"
                  outlined
                  dense
                  v-model="user.lastname"
                  :rules="[rules.required]"/>
            </v-col>

            <v-col cols="9" class="pb-0">
              <v-text-field
                  class="pb-0"
                  label="Straße*"
                  outlined
                  dense
                  v-model="user.street"
                  :rules="[rules.required]"/>
            </v-col>
            <v-col cols="3" class="pb-0">
              <v-text-field
                  class="pb-0"
                  label="Hausnummer*"
                  outlined
                  dense
                  v-model="user.streetNumber"
                  :rules="[rules.required]"/>
            </v-col>
            <v-col cols="4" class="pt-0 ">
              <v-text-field
                  class="pt-0"
                  label="Postleitzahl*"
                  outlined
                  dense
                  v-model="user.plz"
                  :rules="[rules.required]"/>
            </v-col>
            <v-col cols="8" class="pt-0">
              <v-text-field
                  class="pt-0"
                  label="Ort*"
                  outlined
                  dense
                  v-model="user.city"
                  :rules="[rules.required]"/>
            </v-col>
            <v-text-field
                label="Zugangskennung*"
                outlined
                dense
                v-model="user.token"
                :rules="[rules.required]"/>

          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                  label="Email*"
                  type="email"
                  outlined
                  dense
                  v-model="user.email"
                  :rules="[rules.required, rules.email, rules.specialCharacter]"/>

            </v-col>
            <v-col cols="6" class="pt-0">
              <v-text-field
                  label="Passwort*"
                  type="password"
                  outlined
                  dense
                  v-model="user.password"
                  :rules="[rules.required, rules.min, rules.specialCharacter]"/>

            </v-col>
            <v-col cols="6" class="pt-0">
              <v-text-field
                  label="Passwort bestätigen*"
                  type="password"
                  dense
                  outlined
                  v-model="confirmPassword"
                  :rules="[rules.required, rules.min, rules.specialCharacter, rules.confirmPasswordRules]"/>
            </v-col>
          </v-row>

          <v-checkbox
              class="pa-0 ma-0"
              label="Ich bestätige, dass ich die AGBs UND Datenschutzerklärung gelesen habe und stimme diesen zu."
              type="checkbox"
              :rules="[rules.required]"
              v-model="checkedAgbDse"
          ></v-checkbox>
          <a href="https://www.wedding-flow.de/#/agb"
             class=""
             target="_blank"
             title="AGBs ">
            AGBs / Geschäftsbedingungen</a>
          <br>
          <a href="https://www.wedding-flow.de/#/datenschutzerklaerung"
             target="_blank"
             class="mb-3"
             title="Datenschutzerklärung ">Datenschutzerklärung</a>

          <v-btn class="mt-3" block color="primary" depressed type="submit" @click="openAVV">
            Kostenlos Registrieren
          </v-btn>
        </v-card>
      </v-col>
      <v-col cols="7"></v-col>
    </v-row>
    <v-dialog
        v-model="dialogAVV"
        :max-width="900"
    >
      <v-card class="rounded-xl pa-4">
        <v-card-title class="text-h5" id="title">
          Auftragsverarbeitung
        </v-card-title>
        <v-card-text>
          <div class="pa-6 rounded-xl" style="overflow: scroll; width: auto; height: 50vh; background-color: #F5F5F5">
            <AvvC
                :print="false"
                :newUser="user"
            ></AvvC>
          </div><b>
          <v-checkbox
              :label="'Ich bestätige, dass meine Angaben korrekt sind und stimme dem Vertrag über die Auftragsverarbeitung personenbezogener Daten gemäß Art. 28 DSGVO zu.'"
              v-model="avvAccept"
          >
          </v-checkbox></b>
          <small>Du kannst den Vertrag in deinen Accounteinstellungen jederzeit als PDF-Version herunterladen.</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <a
              class="pe-3 grey--text"
              @click="dialogAVV = false"
          >
            Eingabe korrigieren
          </a>
          <v-btn
              color="primary"
              @click="register"
              v-if="avvAccept"
          >
            LOSLEGEN
          </v-btn>
          <v-btn
              color="primary"
              v-else
              disabled
          >
            LOSLEGEN
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {error, msgObj} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const AvvC = () => import("@/components/avvC.vue");

export default {
  name: "RegisterView",
  components: {
    Message,
    AvvC

  },
  data() {
    return {
      message: msgObj(),
      alert: msgObj(),
      user: {},
      email: '',
      password: '',
      confirmPassword: '',
      companyName: '',
      error: '',
      dialogAVV: false,
      avvAccept: false,
      valid: false,
      checkedAgbDse: false,
      e6: 1,
      rules: {
        required: value => !!value || 'Erforderlich',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Ungültige E-mail'
        },
        min: v => v.length >= 8 || 'Mindestens 8 Zeichen',
        specialCharacter: v => {
          const format = /[!@#$%^&*()_+\-={};':"\\|,.<>?]+/;
          return format.test(v) || 'Sonderzeichen sind erforderlich';

        },
        confirmPasswordRules: v => v === this.user.password || "Passwörter müssen gleich sein",
      },
    }
  },
  methods: {
    openAVV(){
      if(this.checkedAgbDse && this.user.firstname && this.user.lastname && this.user.street && this.user.streetNumber && this.user.plz && this.user.city){
        this.dialogAVV = true
      }else{
        this.message = error("Bitte fülle zunächst alle Pflichtfelder aus.")
      }
    },
    register() {
      if (this.checkedAgbDse) {
        let user = this.user
        this.$store.dispatch('auth/newUser', {user}).catch((err) => {
          this.message = error(err)
        })
      } else {
        this.message = error("Bitte akzeptiere zunächst die AGBs, sowie die Datenschutzerklärung, um fortzufahren.")
      }
    }
  },

}
</script>

<style scoped>
OL {
  counter-reset: item;
}
LI {
  display: block;
}
LI:before {
  content: counters(item, ".") ".";
  counter-increment: item;
  padding-right:10px;
  margin-left:-20px;
}
.headLine{
  font-size: large;
  font-weight: bold;
}
v-row {
  width: 100vw !important;
}

h1 {
  font-weight: 300;
}

v-card {
  backdrop-filter: blur(14px) !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.background {
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center left;
}

.transparent-card {
  backdrop-filter: blur(9px) !important;
  background-color: rgba(255, 255, 255, 0.75) !important;
  animation: fadeIn 3s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

</style>
